import React, { Component } from 'react'
import Layout from '../../../components/layout';
import { Banner } from '../../../components/banner';
import { navigate } from '@reach/router'
import { Cache } from "aws-amplify";
import moment from "moment"
import { isLoggedIn } from "../../../services/auth";
import { Col, Input, Form, FormGroup, Label, Button } from 'reactstrap'
import Query from '../../../containers/netsclick/query';
import { getSalesUsers, submitNetsclickRequestorApp, netsclickAppRequestorStatus } from '../../../services/api';
import { APP_STATUS } from '../../../utils/constants'

export default class netsClick extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reqName: null,
            merchantName: null,
            salesUsers: null,
            userObj: null,
            intMethod: null,
            fetched: false,
            enableSubmitBtn: false,
            openEmailModal: false,
            name: null,
            company: null,
            email:  "",
            message: null,
            mobile: null,
            displayError: null,
            validForm: false,
            selectedSalesRep: null,
            requestorsAppList: null,
            displayAppPage: false
        }

    }
    async componentWillMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
        if (Cache.getItem("ma") != 1) {
            navigate(`/account/mfauth/`, { state: { netsClick: true } })
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        }
       
        let data = await getSalesUsers()
        let qryStringParams = this.props.location.search
        let requestorStatus = null
        let displayApplicationOverview = false
        if (qryStringParams && qryStringParams.includes('force')) {
            requestorStatus = []
        } else {
           
            const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
            let email  = ( user && user.userData && user.userData.attributes ) ? user.userData.attributes.email : "";

            requestorStatus = await netsclickAppRequestorStatus(email)
            if(requestorStatus){
                requestorStatus.map(app => {
                    if (app.app_status == 'A') {
                        this.redirectApplicationPage()
                    }
                })
            }
        }
        

        this.setState({
            salesUsers: data,
            requestorsAppList: requestorStatus,
            fetched: true,
            displayAppPage: displayApplicationOverview
        })

    }
    UNSAFE_componentWillUpdate() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }
    }

    async componentDidMount() {
        if (!isLoggedIn()) {
            navigate('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
            if (typeof window !== "undefined") {
                window.location.reload()
            }
        } else {
           
            const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false
            this.setState({
                email: (  user && user.userData && user.userData.attributes) ? user.userData.attributes.email : "",
                userObj : user
            })
        }

    }
    redirectPage() {
        navigate(`/account/mfauth`, { props: 'netsclick' })
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }
    renderSalesUserList() {
        if (this.state.salesUsers) {
            return this.state.salesUsers.map(function (value) {
                let obj = {
                    name: value.name,
                    email: value.email
                }
                obj = JSON.stringify(obj)
                return (
                    <option value={obj} key={value.email} >{value.name}</option>
                )
            });
        }
    }
    redirectApplicationPage() {
        navigate(`/product/netsclick/application`)
        if (typeof window !== "undefined") {
            window.location.reload()
        }
    }
    setSalesRep(value) {
        this.setState({
            selectedSalesRep: value
        }, function () {
            this.validateFormData()
        })
    }
    requestorHandleChange(e) {
        let { id, value } = e.target
        console.log(value.length)
        const alphanumericRegex =  /^[a-zA-Z0-9\s]*$/;

        // Check if the input value matches the alphanumeric regex and is within 25 characters
        if (alphanumericRegex.test(value) && value.length <= 25) {
            // Update the state with the valid input value
            this.setState({
                [id]: value
            }, function () {
                this.validateFormData()
            })
        }


    }
    validateFormData() {
        let { reqName, merchantName, intMethod } = this.state

        if (reqName && merchantName && intMethod) {
            this.setState({
                enableSubmitBtn: true
            })
        } else {
            this.setState({
                enableSubmitBtn: false
            })
        }
    }
    async handleSubmit(event) {
        
        event.preventDefault();
        let { reqName, merchantName, selectedSalesRep, intMethod, email } = this.state
        let reqInputObject = {
            name: reqName,
            merchant: merchantName,
            salesrep: selectedSalesRep,
            intMethod: intMethod,
            email: email
        }
        this.setState({
            fetched: false
        })
        await submitNetsclickRequestorApp(reqInputObject)

        let qryStringParams = this.props.location.search
        if (qryStringParams && qryStringParams.includes('force')) {
            this.redirectApplicationPage()
        } else {
            window.location.reload()
        }



    }
    modalViewControl(value) {
        if (!value) {
            this.setState({
                openEmailModal: value,
                name: null,
                company: null,
                mobile: null,
                message: null,
                email: null,
                displayError: false,
                validForm: false
            })
        } else {
            this.setState({
                openEmailModal: value
            })
        }

    }
    handleChange = event => {
        const name = event.target.id;
        const value = event.target.value;
        if (name == 'mobile' && value.length > 14) {
            return
        }
        this.setState({
            [name]: value,
            displayError: false
        },
            () => {
                this.validateForm()
            }
        );
    }
    validateForm() {
        this.setState({
            validForm:
                this.state.name && this.state.company && this.state.mobile && this.state.captcha
        })
    }
    render() {
        let { fetched, selectedSalesRep, requestorsAppList, displayAppPage } = this.state

        let totalnoOfAllpcalition = requestorsAppList ? requestorsAppList.length : null
        let salesRep = null
        if (totalnoOfAllpcalition >= 1) {
            salesRep = requestorsAppList[totalnoOfAllpcalition - 1]['salesrep']
            salesRep = JSON.parse(salesRep)
        }
        let netsClickAppTotalSize = requestorsAppList ? requestorsAppList.length : 0
        
        if(netsClickAppTotalSize > process.env.NETSCLICK_MAX_APP_SIZE){
            return <div>You have exceeded maximum no of netsclick application.</div>
        }
        if (!fetched) {
            return <div class="loading"></div>
        }
        return (
            <Layout>
                <Banner title={`NETS Click`} />
                <div className='leftAligned'>
                    <h4><b>Request for access to the NETS Click merchant integration developer portal.</b></h4>
                    <h4><b>Only merchants who are integrating NETS Click will be given access.</b></h4>
                    <h4 className='topAligned'><b>Submit your request for access :</b></h4>
                    <div className="requesterForm">
                        {requestorsAppList && requestorsAppList.length == 0 ? <Form>
                            <FormGroup row>
                                <Label
                                    for="reqName"
                                    sm={6}
                                >
                                    <b>Requester Name : </b>
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        id="reqName"
                                        name="reqName"
                                        value={this.state.reqName}
                                        onChange={(e) => { this.requestorHandleChange(e) }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="merchantName"
                                    sm={6}
                                >
                                    <b>Merchant Registered Name : <br /></b>
                                    <div><small>(as per name indicated in the NETS application form)</small></div>
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        id="merchantName"
                                        name="merchantName"
                                        value={this.state.merchantName}
                                        onChange={(e) => { this.requestorHandleChange(e) }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="selectedSalesRep"
                                    sm={6}
                                >
                                    <b>Sales / RM (if any) : </b>
                                </Label>
                                <Col sm={6}>
                                    <Input type="select" id='selectedSalesRep' name="selectedSalesRep" value={selectedSalesRep} onChange={(e) => this.setSalesRep(e.target.value)}>
                                        <option>Choose Sales Person (Optional)</option>
                                        {this.renderSalesUserList()}
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="intMethod"
                                    sm={6}
                                >
                                    <b>Integration Method : </b>
                                </Label>
                                <Col sm={6}>
                                    <FormGroup >
                                        <div><Input type="radio" id='intMethod' name='intMethod' value='sdk' onChange={(e) => { this.requestorHandleChange(e) }} />
                                            {' '} SDK</div>
                                        {/* <div> <Input type="radio" id='intMethod' name='intMethod' value='api' onChange={(e) => { this.requestorHandleChange(e) }} />
                                            {' '} API</div> */}
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <Button
                                className='centerAligned'
                                color="primary"
                                tag="input"
                                type="submit"
                                value="Submit"
                                onClick={(e) => this.handleSubmit(e)}
                                disabled={!this.state.enableSubmitBtn}
                            />
                        </Form> : <Form>
                            <FormGroup row>
                                <Label
                                    for="reqName"
                                    sm={6}
                                >
                                    <b>Requester Name : </b>
                                </Label>
                                <Col sm={6}>
                                    <div>{requestorsAppList ? requestorsAppList[totalnoOfAllpcalition - 1].name : ''}</div>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="merchantName"
                                    sm={6}
                                >
                                    <b>Merchant Registered Name : <br /></b>
                                    <div><small>(as per name indicated in the NETS application form)</small></div>
                                </Label>
                                <Col sm={6}>
                                    <div>{requestorsAppList ? requestorsAppList[totalnoOfAllpcalition - 1].merchant_name : ''}</div>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="salesRep"
                                    sm={6}
                                >
                                    <b>Sales / RM (if any) :</b>
                                </Label>
                                <Col sm={6}>
                                    <div>{salesRep ? salesRep.name : ''}</div>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                    for="intMethod"
                                    sm={6}
                                >
                                    <b>Integration Method :</b>
                                </Label>
                                <Col sm={6}>
                                    <FormGroup >

                                        {' '}
                                        <Label >
                                            {requestorsAppList ? requestorsAppList[totalnoOfAllpcalition - 1].int_method : ''}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <b>Request Date :</b>
                                </Col>
                                <Col sm={6}><b>{requestorsAppList ? moment(requestorsAppList[totalnoOfAllpcalition - 1].submitted_at).format('DD/MM/YYYY') : ''}</b></Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <b>Request Status :</b>
                                </Col>

                                <Col sm={6}><b>{requestorsAppList ? APP_STATUS[requestorsAppList[totalnoOfAllpcalition - 1].app_status] : ''}</b></Col>
                            </FormGroup>

                        </Form>}
                        {displayAppPage ? <Button color="primary" className='leftAligned1' onClick={() => this.redirectApplicationPage()}>Go to Appliaction Page</Button> : null}
                    </div>

                </div>
               
                <Query salesLead={ true } />
            </Layout>
        )
    }

}
